







































































import { getOperatorsMerchant } from '@/api/SuperadminCustody';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { textOverflowMiddle } from '@/helpers/formatString';
import type { AxiosError } from 'axios';
import type {
  VuetifyTableOptions,
  OperatorMerchantsRequestParams,
  OperatosMerchantResponce
} from '@/api/schema';
import { MerchantData, BlockchainNetworkURL } from '@/api/schema';
import SuperAdminCustodyWallet from '@/views/SuperAdminViews/super-admin-custody/SuperAdminCustodyWallet.vue';

export default {
  name: 'SuperAdminCustodyOperators',
  components: { SuperAdminCustodyWallet },
  data(): any {
    return {
      BlockchainNetworkURL,
      filterSelect: 'name',
      filterItems: [
        { text: 'Brand Name', value: 'name' },
        { text: 'Deposit Address', value: 'address' }
      ],
      searchPhrase: '',
      merchants: [] as MerchantData[],
      pageData: {
        page: 1,
        itemsPerPage: 10
      },
      loading: false,
      total: 0,
      headers: [
        { text: 'ID', value: 'id', width: '10%' },
        { text: 'Brand Name', value: 'name', width: '40%' },
        { text: 'Token', value: 'token', width: '15%' },
        { text: 'Deposit Address', value: 'address', width: '40%' }
      ],
      formIsValid: true
    };
  },
  computed: {
    disableSubmit(): boolean {
      return !this.filterSelect || !this.searchPhrase;
    },
    currentPage(): number {
      return this.pageData.page - 1;
    }
  },
  watch: {
    disableSubmit(newStatus: boolean): void {
      if (newStatus) this.getMerchants(true);
    }
  },
  methods: {
    copyToClipBoard,
    textOverflowMiddle,
    getMerchants(firstPage: boolean = false): void {
      if (firstPage) this.pageData.page = 1;

      const searchParams: OperatorMerchantsRequestParams = {
        page: this.currentPage,
        size: this.pageData.itemsPerPage,
        ...(!this.disableSubmit && { [this.filterSelect]: this.searchPhrase })
      };

      this.loading = true;

      getOperatorsMerchant(searchParams)
        .then((data: OperatosMerchantResponce): void => {
          this.setMerchantData(data);
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading = false;
        });
    },
    setMerchantData(data: OperatosMerchantResponce): void {
      this.merchants = data.content;
      this.total = data.totalElements;
    },
    updateOptionsHandler(opts: VuetifyTableOptions): void {
      this.pageData = opts;
      this.getMerchants();
    },
    goToMerchantInfo(item: MerchantData): void {
      this.$router.push({
        name: 'merchant',
        params: { merchantId: item.id.toString() }
      });
    }
  }
};
