

























import SuperAdminCustodyOperators from '@/views/SuperAdminViews/super-admin-custody/SuperAdminCustodyOperators.vue';
import SuperAdminCustodyTransactions from '@/views/SuperAdminViews/super-admin-custody/SuperAdminCustodyTransactions.vue';
import VTabsHash from '@/components/VTabsHash.vue';
import type { TTabsItem } from '@/api/schema';

export default {
  name: 'SuperAdminCastodyView',
  data(): any {
    return {
      tab: 0
    };
  },
  components: {
    VTabsHash,
    SuperAdminCustodyOperators,
    SuperAdminCustodyTransactions
  },

  computed: {
    tabs(): TTabsItem[] {
      return [
        {
          id: 'all-merchants',
          text: 'All Merchants',
          component: 'super-admin-custody-operators'
        },
        {
          id: 'all-transactions',
          text: 'All Transactions',
          component: 'super-admin-custody-transactions'
        }
      ];
    }
  }
};
