


















import { textOverflowMiddle } from '@/helpers/formatString';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';

import { getFinanceWalletBalance } from '@/api/SuperadminCustody';

export default {
  name: 'SuperAdminCustodyWallet',

  data(): any {
    return {
      address: null,
      balance: 0
    };
  },

  created(): void {
    this.getFinanceWalletBalance();
  },

  methods: {
    textOverflowMiddle,
    copyToClipBoard,

    getFinanceWalletBalance(): void {
      getFinanceWalletBalance().then((response) => {
        this.address = response.address;
        this.balance = response.balance;
      });
    }
  }
};
