



























































































































































import { DateTime } from 'luxon';
import {
  BlockchainNetworkCurrencyLabel,
  BlockchainNetworkURL,
  TransactionTypeWithNetworkNaming,
  FiltersTransactionTypeWithNetworkMapConfig,
  CustodyTransactionData,
  CustodyTransactionRequestParams,
  VuetifyTableOptions
} from '@/api/schema';
import { textOverflowMiddle } from '@/helpers/formatString';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import {
  getOperatorsTransactions,
  getOperatorTransactionsCSVReport
} from '@/api/SuperadminCustody';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import formatDate from '@/helpers/formatDate';
import { roundNumber } from '@/helpers/roundNumber';
import { doDownload } from '@/helpers/download';

const defaultDateData = {
  from: DateTime.utc().startOf('month').toFormat('yyyy-LL-dd'),
  to: DateTime.utc().toFormat('yyyy-LL-dd')
};

export default {
  name: 'SuperAdminCustodyTransactions',
  components: {
    VExtendedDataPicker
  },
  data(): any {
    return {
      BlockchainNetworkCurrencyLabel,
      BlockchainNetworkURL,
      TransactionTypeWithNetworkNaming,
      FiltersTransactionTypeWithNetworkMapConfig,
      isLoading: false,
      isLoadingCsv: false,
      filterMenu: false,
      searchPhrase: '',
      searchFilter: 'from',
      searchFilterOptions: [
        { text: 'Wallet From', value: 'from' },
        { text: 'Wallet To', value: 'to' }
      ],
      dates: { ...defaultDateData },
      type: '',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Date (UTC)', value: 'date', width: '150' },
        { text: 'Transaction Type', value: 'type', width: '18%' },
        { text: 'Wallet From', value: 'from', width: '150' },
        { text: 'To Wallet', value: 'to', width: '150' },
        { text: 'Token Amount', value: 'amount' },
        { text: 'Fee', value: 'fee' },
        { text: 'Transaction Hash', value: 'hash', width: '200' }
      ],
      pageData: {
        page: 1,
        itemsPerPage: 10
      },
      total: 0,
      transactions: [] as CustodyTransactionData[]
    };
  },
  computed: {
    currentPage(): number {
      return this.pageData.page - 1;
    },
    formPayload(): CustodyTransactionRequestParams {
      return {
        page: this.currentPage,
        size: this.pageData.itemsPerPage,
        ...(this.type && { type: this.type }),
        ...(this.searchPhrase && {
          [this.searchFilter]: this.searchPhrase
        }),
        fromDate: this.dates.from,
        toDate: this.dates.to
      };
    }
  },
  watch: {
    searchPhrase(newVal: string): void {
      if (!newVal) {
        this.loadData(true);
      }
    }
  },
  methods: {
    textOverflowMiddle,
    copyToClipBoard,
    formatDate,
    roundNumber,
    async loadData(firstPage: boolean = false): Promise<void> {
      try {
        this.isLoading = true;

        if (firstPage) this.pageData.page = 1;

        const res = await getOperatorsTransactions(this.formPayload);

        this.transactions = res.content;
        this.total = res.totalElements;
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    getCsvReport(): void {
      this.isLoadingCsv = true;

      getOperatorTransactionsCSVReport({
        ...this.formPayload,
        size: 10000,
        page: 0
      })
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const date = Object.values(this.dates)
            .map((item: string) => item.replaceAll('-', '.'))
            .join('-');

          doDownload(url, `custody-transactions-report[${date}].csv`);
        })
        .catch((error) => {
          errorToastMessage(error);
          console.error(error);
        })
        .finally(() => {
          this.isLoadingCsv = false;
        });
    },
    changeDateHandler(value: string[]): void {
      this.dates = value;
      this.loadData(true);
    },
    updateOptionsHandler(options: VuetifyTableOptions): void {
      this.pageData = options;
      this.loadData();
    },
    formatToCurrency(value: number): string {
      return this.$options.filters.numeralSpaces(value, '0,0.[00]');
    },
    setTypeFilter(action: 'clear' | 'apply' = 'apply'): void {
      if (action === 'clear') {
        this.type = '';
      }

      this.filterMenu = false;
      this.loadData(true);
    }
  }
};
